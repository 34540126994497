class Session {
  get meterId() {
    return localStorage.getItem('meter_id');
  }

  set meterId(meterId) {
    localStorage.setItem('meter_id', meterId);
  }

  get plantOrUniqueCode() {
    return localStorage.getItem('plant_or_unique_code');
  }

  set plantOrUniqueCode(code) {
    localStorage.setItem('plant_or_unique_code', code);
  }

  get postalCode() {
    return localStorage.getItem('postal_code');
  }

  set postalCode(postalCode) {
    localStorage.setItem('postal_code', postalCode);
  }

  get ref() {
    return localStorage.getItem('ref');
  }

  set ref(ref) {
    localStorage.setItem('ref', ref);
  }

  get isMultipleMeters() {
    return localStorage.getItem('multiple_meters');
  }

  set isMultipleMeters(isMultipleMeters) {
    return localStorage.setItem('multiple_meters', isMultipleMeters);
  }

  set companyConfig(companyConfig) {
    localStorage.setItem('company_config', JSON.stringify(companyConfig));
  }

  get companyConfig() {
    return JSON.parse(localStorage.getItem('company_config'));
  }

  flush() {
    localStorage.clear();
  }
}

export default new Session();
