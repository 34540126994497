import axios from 'axios';
import moment from 'moment';

export default class SiriusApi {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE,
      headers: { 'X-Client-Id': 'Sirius' },
    });
  }

  /**
   * @param postalCode The postal code of the plant
   * @param plantOrUniqueCode The plant- or unique code of the meter
   * @returns {Promise} A promise containing the response, or a thrown error.
   */
  getPlantDetails(postalCode, plantOrUniqueCode) {
    return this.client.get(
      `/api/plant-details?plant_code=${plantOrUniqueCode}&postal_code=${postalCode}`
    );
  }

  /**
   * @returns {Promise} A promise containing the response, or a thrown error.
   */
  getMeterPlant(postalCode, plantOrUniqueCode, meterId) {
    return this.client.get(
      `/api/meter-plant-details?plant_code=${plantOrUniqueCode}&postal_code=${postalCode}&meter_id=${meterId}`
    );
  }

  /**
   * @param weatherStationId The weather station to fetch the data from
   * @returns {Promise} A promise containing the response, or a thrown error.
   */
  getWeather(weatherStationId) {
    const start = moment().subtract(11, 'years').format('YYYY-MM-DD');
    const end = moment().subtract(1, 'days').format('YYYY-MM-DD');

    return this.client.get(
      `/api/irradiance-in-period?station=${weatherStationId}&start=${start}&end=${end}`
    );
  }
}
